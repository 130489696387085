.product-detail {
	h1 {
		margin-bottom:1em;
	}
	.product-detail-thumbnail {
		display: block;
		position:relative;
		.news {
			position: absolute;
			background-color: $brand-primary;
			color: #fff;
			padding: 0 10px;
			top: -5px;
			right: 5px;
			font-weight: normal;
			font-style: normal;
			text-transform: uppercase;
			line-height: 1.6em;
			font-size: 0.9em;
		}
	}
	.img-thumbnail{
		@media(max-width: $screen-xs-max){
			margin-bottom:2em;
		}
	}
}
.product-details-table{
	.table-label{
		font-weight:700;
		width:180px;
	}
	tbody > tr > td{
		border-top-style: dashed;
		text-align: left;
		vertical-align: middle;
	}
	.product-icon {margin:0 5px; height:50px; width:50px; display:inline-block;}
	.product-icon:first-child { margin-left:0; }
}
.filters {
	.products-filter {
		font-family: $font-family-sans-serif;
		font-weight: 900;
		font-size: 14px / $font-size-base * 1em;
	}
	.filter {
		margin-bottom: .5em;
	}
}
.product-grid {
	.product {
		margin-bottom: 1.5em;

		&>a{
			display: block;
			position:relative;
		}

		.news {
			position: absolute;
			background-color: $brand-primary;
			color: #fff;
			padding: 0 10px;
			top: -5px;
			right: 5px;
			font-weight: normal;
			font-style: normal;
			text-transform: uppercase;
			line-height: 1.6em;
			font-size: 0.9em;
		}

		.product-title {
			font-family: $font-family-sans-serif;
			font-weight: 900;
			font-size: 14px;
			text-transform: uppercase;
			text-align:center;
			color: $gray-darker;
			text-decoration: none;
			font-style: normal;
			margin-top: .8em;
		}
		.img-thumbnail{
			margin: 0 auto;
			display: block;
		}
	}
}
.product-grid-pagination {
	text-align: center;
	.pagination {
		li {
			display: inline-block;
			margin-left:.5em;
			margin-right:.5em;
			a { font-style:normal; color: $gray-dark; }
			&.active span {
				background-color: $gray;
				border-color: $gray;
			}
		}
	}

}