#sidebar {
	background-color: $gray-lighten;
	color: $gray-dark;
	.sidebar-title {
		background-color: $gray;
		color: #fff;
		font-size: 32px / $font-size-base * 1em;
		padding: .6em 1em;
		margin-bottom:0;
	}
	.shop-product-categories {
		padding: 0;
		margin: 0;
		border-top:1px solid #fff;
		list-style-type: none;
		li{	
			&:hover, &.active {
				background-color: $gray;
				&:before {
					color: #fff;
				}
				a {
					color: #fff;
					text-decoration:none;
				}
			}
			padding: 1em 2em .8em;
			border-bottom: 1px solid #fff;
				&:before {
					content: "\e250";
					font-family: 'Glyphicons Halflings';
					margin-left: -1em;
					margin-top:2px;

				}
			a { 
				text-transform: uppercase; 
				padding-left:.2em;
				display: inline-block;
				vertical-align: top;
				color: $gray-dark;
				font-style: normal;
			}
		}
	}
	.industry-product-categories {
		padding: 0;
		margin: 0;
		border-top:1px solid #fff;
		.catalog-title { text-transform: uppercase; padding:0 1em; font-weight:900; }
		ul {
			padding: 0;
			margin: 0;
			padding-bottom:1em;
			list-style-type: none;
			padding-left: 0;
			padding-right: 0;
			li{	
				&:hover, &.active {
					background-color: $gray;
					&:before {
						color: #fff;
					}
					a {
						color: #fff;
						text-decoration:none;
					}
				}
				padding: 0;
				a { 
					// text-transform: uppercase; 
					padding-left:2em;
					display: inline-block;
					vertical-align: top;
					color: $gray-dark;
					font-style: normal;
				}
			}
		}
	}
}