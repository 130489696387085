#menu-second{
    font-size: 16px / $font-size-base * 1em;
    background-color: $gray-lighten;
	// background-color: #dce0e1;
    border: 0 ;
    li{
    	// color:#96a6a6;
    	text-transform: uppercase;
    	font-weight: 700;
		& > a{
            font-style: normal;
			color: $gray-dark;
            padding: 20px 40px;
            @media(max-width:$screen-sm-max) {
                padding:20px 20px;
            }

    	}
    	&.active{
            & > a{
    		  background-color:$gray;
    		  color:#fff;
    		}
    	}
    }
    ul{
    	&.search{
    		background:  $gray;
    		li{
    			& > a{
                    padding:20px 30px;
    				color:#fff;
    			}
    		}
    	}
    }
}
#menu-top{
	.navbar-brand {
	    height: 90px;
        padding-left: 0;
	    @media(max-width: $screen-xs-max){
	    	width: 75%;
	    }
	}
	.navbar-toggle{
		margin-top: 24px;
		
	}
}
.navbar-transparent {
    background-color: transparent;
    min-height: 90px;
    li{
    	color:#96a6a6;
    	p{
    		color:#96a6a6;
    	}
		a{
            
            font-style: normal;
			color:#96a6a6;
    		&:focus, &:hover{
    			color: #555;
    		}
    		&.lang{
    			text-transform: uppercase;
    		}
    	}
    	&.active{
    		a{
    			color:#e73237;
    			&.lang{
    				background:#96a6a6;
    				color:#fff;
    			}
    		}

    	}
    }
    .navbar-toggle {
    	background: #96a6a6;
    	.icon-bar {
	        background: #fff;
    	}
    }
}
#menuTop{
	&>ul{
		margin: 20px 0;
	}
}
