.flexslider {
	border:none;
	.flex-direction-nav, .flex-pauseplay {
		a{
			line-height:1;
			font-style: normal;
		}
	}
	.caption {
		@extend .slider-caption;
	}
}

.img-with-caption { 
	position:relative; 
	.caption {
		@extend .slider-caption;
	}

}

.slider-caption {
	background-color: #fff;
	padding: 1em;
	color:$text-color;
	font-style:normal;
	.caption-title { 
		font-weight: 900; 
	}
	@media (min-width:$screen-md-min) {
		position:absolute;
		padding: 1em 2em;
		bottom:0;
		// left:0;
		background-color: rgba(255,255,255,.8);
		.caption-title {
			font-size: 1.8em;
			margin-bottom:0;
		}
		font-size:1.4em;
	}
}