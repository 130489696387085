$spaceamounts: (0, 1, 2, 3, 4, 5, 6);

@mixin generate-spacer() {
  @each $space in $spaceamounts {
    .spacer-#{$space} {
      margin-top: #{$space}em;
      margin-bottom: #{$space}em;
    }
    .spacer-top-#{$space} {
      margin-top: #{$space}em;
    }
    .spacer-bottom-#{$space} {
      margin-bottom: #{$space}em;
    }
    .spacer-in-#{$space} {
      padding-top: #{$space}em;
      padding-bottom: #{$space}em;
    }
    .spacer-in-top-#{$space} {
      padding-top: #{$space}em;
    }
    .spacer-in-bottom-#{$space} {
      padding-bottom: #{$space}em;
    }
    .spacer-in-left-#{$space} {
      padding-left: #{$space}em;
    }
    .spacer-in-right-#{$space} {
      padding-right: #{$space}em;
    }
  }
}


@include generate-spacer();