@import 'fa-variables';
@import 'bo-variables';
@import 'fs-variables';
@import '../vendor/bootstrap/stylesheets/bootstrap';
@import '../vendor/fontawesome/scss/font-awesome';
@import '../vendor/flexslider/flexslider';
@import 'theme/spacer';
@import 'theme/main';
@import 'theme/head';
@import 'theme/flexslider';
@import 'theme/product';
@import 'theme/sidebar';
@import 'theme/categories-list';
@import 'theme/footer';

a{
	&, &:focus,&:hover{
		outline: none !important;
	}
}
.btn { text-transform:uppercase;}
.clear {clear:both;}

.text-style-1 { font-family: $font-family-sans-serif; }
.text-style-2 { font-family: $font-family-sans-serif2; }

.text-white { color: #fff; }
.text-gray{ color: $gray; } //#96a6a6 
.text-gray-light{ color: $gray-light; } //#d1dbdb 
.text-gray-lighten{ color: $gray-lighten; } //#dce0e0
.text-gray-lighter{ color: $gray-lighter; } //#f2f2f2 

.bg-gray{ background-color: $gray; } //#96a6a6 
.bg-gray-light{ background-color: $gray-light; } //#d1dbdb 
.bg-gray-lighten{ background-color: $gray-lighten; } //#dce0e0
.bg-gray-lighter{ background-color: $gray-lighter; } //#f2f2f2 