.categories-list {
	.category-title {
		font-size: 18px / $font-size-base * 1em;
		font-family: $font-family-sans-serif;
		font-weight: 900;
		text-transform: uppercase;
		margin-bottom:1.5em;
		color: $gray-dark;
		font-style: normal;
	}
	ul {
		list-style-type: none;
		padding:0;
		li {
			padding: .1em 1em;
			&:before {
				content: "\f105";
				font-family: FontAwesome;
				margin-left: -1em;
				margin-top:2px;

			}
			a {
				padding-left:.4em;
				display: inline-block;
				vertical-align: top;
				color: $gray-dark;
				font-style: normal;
			}
		}
	}
	.img-thumbnail{
		margin: 0 auto;
		display: block;
	}

}