#footer{
	.service{		
		h3{
			text-transform: uppercase;
		}
		.mail{
			.fa-envelope-square{
				color: #96a6a6;
				font-size: 24px;
				display: inline-block;
				vertical-align: middle;
			}
			a{
				margin-left: 0.5em;
				display: inline-block;
				vertical-align: middle;
				font-style: italic;
			}
		}
	}
	.privacy{
		label{
			font-size: 10px;
			a{
				color: #96a6a6;
				text-decoration: underline;
			}
		}
	}
}