@import url(https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:400,700,300|Roboto:400,300,500,700,900,100,500italic,700italic,400italic,300italic);
body{ background:#fff url('img/bg.png') top left repeat-x; }

hr{	border: 1px solid #d7d7d7;}
a{	
	font-style: italic;
	&.btn{
		font-style: normal;
	}
}
.more{font-weight:700;}
.bg-ciano{background: #00a8e0;}
.bg-arancio{background:#f37a2a;}
.bg-petrolio{background:#009a9a}

.home-box{
	hr{
		width:85%;
		margin:0;
	}
	p{
		margin-top: 1em;
	}
}
.box{
	border: 1px solid #d7d7d7;
	background: #fff;
}
#focus-flat{
	background: #96a6a6;
	color:#fff;
	img{
		height: 80%;
		width: 80%;
		margin: 7% 0;
		@media(max-width: $screen-sm-max){
			display: block;
			height: 150px;
			width: 150px;
			margin: 0% auto;
		}
		@media(max-width: $screen-xs-max){
			height: 90px;
			width: 90px;
		}
	}
}
.box-greylight{
	background: $gray-lighter;
	ul {
		list-style: none;
		-webkit-padding-start: 0;
		li{
			padding-bottom: 1em;
			font-weight: 700;
			text-transform: uppercase;
			.fa{
				display: inline-block;
				vertical-align: top;
				color: #96a6a6;
				font-size: 1em;
				margin-right:0.2em;
			}
			p{
				display: inline-block;
				width: 80%;
			}
		}
	}
	a{
		text-align: center;
	}
}
.list-cert{
	ul{
	list-style: none;
	-webkit-padding-start: 0;
		li{
			margin-bottom: 1em;
		}
	}
}
.vantaggi{
	ul {
		list-style: none;
		-webkit-padding-start: 0;
		li{
			margin-bottom: 1em;
			.fa{
				display: inline-block;
				vertical-align: top;
				color: #96a6a6;
				font-size: 2.3em;
				margin-right:0.2em;
			}
			p{
				display: inline-block;
				width: 80%;
			}
		}
	}
}
.software{
	h2{ 
		display: inline-block;
		border-bottom: 2px solid #d7d7d7;
	}
}
.heading{
	img{
		display: inline-block;
		vertical-align: middle;
	}
	h3{
		display: inline-block;
		vertical-align: middle;
	}
}
// .sfera{
// 	img{
// 		margin:0 auto;
// 		@media(max-width: $screen-xs-max){
// 			margin-bottom: 2em;
// 		}
// 	}
// 	.plus{
// 		p{
// 			background: $gray-light;
// 		}
// 	}
// }
// .software-icon{
// 	img{
// 		display: inline-block;
// 		vertical-align: middle;
// 	}
// 	p{
// 		display: inline-block;
// 		vertical-align: middle;
// 		width: 75%;
//     	margin-left: 1em;
// 	}
// }